import {callAPI, createActions} from '../../../../commons/actions/data-action-helper'
import {GetState} from '../types/state'
import {convertFromNewToOldSettings} from '../utils/settings'
import {reloadEvents} from './events'

export const UPDATE_STYLE_PARAMS = 'UPDATE_STYLE_PARAMS'
export const UPDATE_COMPONENT_DRAFT = createActions('UPDATE_COMPONENT_DRAFT')
export const UPDATE_COMPONENT = createActions('UPDATE_COMPONENT')
export const UPDATE_COMPONENT_TEXTS = 'UPDATE_COMPONENT_TEXTS'

export const INJECT_COMPONENT_DRAFT = 'INJECT_COMPONENT_DRAFT'

export const updateStyleParams = (payload: object) => ({type: UPDATE_STYLE_PARAMS, payload})

export const updateComponentDraft = (component: wix.events.editor.WebComponentConfig, reload = true) => async (
  dispatch: Function,
  getState: GetState,
) => {
  const {membersEnabled, paidPlansEnabled} = getState()
  await dispatch(callAPI(UPDATE_COMPONENT_DRAFT, component, membersEnabled, paidPlansEnabled))
  if (reload) {
    await dispatch(reloadEvents())
  }
}

export const updateComponentTexts = (payload: object) => ({type: UPDATE_COMPONENT_TEXTS, payload})

export const updateComponent = () => (dispatch: Function, getState: GetState) => {
  const state = getState()
  const {byStatus, byEventId, byCategoryId} = state.component
  const settings = convertFromNewToOldSettings(state.component.settings, Object.keys(state.component.params || {}))

  dispatch(callAPI(UPDATE_COMPONENT, {settings, byStatus, byEventId, byCategoryId}))
}
