import {RIBBON} from '../../../../commons/constants/ribbons'
import {DesignSection, TextSection} from '../../../../commons/enums'
import {createAsyncAction} from '../services/redux-toolkit'
import {WidgetSettingsEventsKeys} from '../../interfaces'

export const showRibbon = createAsyncAction<void, RIBBON>(
  'SHOW_RIBBON',
  async (ribbon, {extra: {experiments, settings}}) => {
    if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
      settings.publishEvent(WidgetSettingsEventsKeys.ShowRibbon, ribbon)
    }
  },
)

export const hideRibbon = createAsyncAction('HIDE_RIBBON', async (_, {extra: {experiments, settings}}) => {
  if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
    settings.publishEvent(WidgetSettingsEventsKeys.HideRibbon, '')
  }
})

export const collapseEvent = createAsyncAction('COLLAPSE_EVENT', async (_, {extra: {experiments, settings}}) => {
  if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
    settings.publishEvent(WidgetSettingsEventsKeys.CollapseEvent, '')
  }
})

export const expandEvent = createAsyncAction('EXPAND_EVENT', async (_, {extra: {experiments, settings}}) => {
  if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
    settings.publishEvent(WidgetSettingsEventsKeys.ExpandEvent, '')
  }
})

export const settingsSectionChanged = createAsyncAction<void, DesignSection>(
  'SETTINGS_SECTION_CHANGED',
  async (designSection, {extra: {experiments, settings}}) => {
    if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
      settings.publishEvent(WidgetSettingsEventsKeys.SettingsSectionChanged, designSection)
    }
  },
)

export const textsSectionChanged = createAsyncAction<void, TextSection>(
  'TEXTS_SECTION_CHANGED',
  async (textSection, {extra: {experiments, settings}}) => {
    if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
      settings.publishEvent(WidgetSettingsEventsKeys.TextsSectionChanged, textSection)
    }
  },
)
