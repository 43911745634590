export enum WidgetSettingsEventsKeys {
  TabChanged = 'tabChanged',
  SettingsSectionChanged = 'settingsSectionChanged',
  ExpandEvent = 'expandEvent',
  CollapseEvent = 'collapseEvent',
  ShowRibbon = 'showRibbon',
  HideRibbon = 'hideRibbon',
  TextsSectionChanged = 'textsSectionChanged',
  InjectComponentDraft = 'injectComponentDraft',
  HideEvent = 'hideEvent',
}
