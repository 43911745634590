import {LIST_LAYOUT, WIDGET_LAYOUT, WIDGET_TYPE} from '@wix/wix-events-commons-statics'
import {settingsTabClicked} from '@wix/bi-logger-events-users/v2'
import {INNER_PAGE, PAGE, TAB} from '../../../../commons/constants/navigation'
import {ARTICLES} from '../constants'
import {createAsyncAction} from '../services/redux-toolkit'
import {buildPath, getHistory, getNavigationInfo} from '../utils/history'
import {getGeneralFields} from '../selectors/bi'
import {WidgetSettingsEventsKeys} from '../../interfaces'

interface NavigateToCommonParams {
  widgetType: WIDGET_TYPE
  widgetLayout: LIST_LAYOUT | WIDGET_LAYOUT
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const refreshTabNavigation = (params: NavigateToCommonParams) => navigateTo(params)

interface NavigateToInnerPageParams extends NavigateToCommonParams {
  innerPage: INNER_PAGE | string
}

export const navigateToInnerPage = (params: NavigateToInnerPageParams) => navigateTo(params)

export const navigateToLanding = createAsyncAction<void, NavigateToCommonParams>(
  'NAVIGATE_TO_LANDING',
  (params, {dispatch}) => dispatch(navigateTo({...params, page: PAGE.LANDING})),
)

interface NavigateToParams extends NavigateToCommonParams {
  tab?: TAB
  page?: PAGE
  innerPage?: INNER_PAGE | string
  useEditorSdkHelpArticle?: boolean
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const navigateTo = createAsyncAction<void, NavigateToParams>(
  'NAVIGATE_TO',
  async (navigationInfo, {dispatch}) => {
    const {tab, multiEventWidget, calendar, recurringFilter} = navigationInfo
    const actualNavigationInfo = getNavigationInfo(true)

    if (tab && tab !== actualNavigationInfo.tab) {
      dispatch(
        tabChanged({
          tab,
          previousTab: actualNavigationInfo.tab,
          useEditorSdkHelpArticle: navigationInfo.useEditorSdkHelpArticle,
          multiEventWidget,
          calendar,
          recurringFilter,
        }),
      )
    }

    getHistory().push(buildPath(navigationInfo))
  },
)

interface TabChangedParams {
  tab: TAB
  previousTab: TAB
  useEditorSdkHelpArticle?: boolean
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const tabChanged = createAsyncAction<{tab: TAB; previousTab: TAB}, TabChangedParams>(
  'TAB_CHANGED',
  async (
    {tab, previousTab, useEditorSdkHelpArticle, multiEventWidget, calendar, recurringFilter},
    {getState, extra: {editorSDK, bi, settings, experiments}},
  ) => {
    const state = getState()

    if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
      settings.publishEvent(WidgetSettingsEventsKeys.TabChanged, tab)
    }

    bi.report(
      settingsTabClicked({
        tab_name: `${multiEventWidget ? 'list_' : ''}${tab}`,
        last_tab_name: `${multiEventWidget ? 'list_' : ''}${previousTab}`,
        editor_view_mode: 'desktop',
        origin: 'main_page',
        ...getGeneralFields({
          state,
          multiEventWidget,
          calendar,
          recurringFilter,
        }),
      }),
    )

    if (state.panelToken && useEditorSdkHelpArticle) {
      editorSDK.editor.updatePanelOptions(state.panelToken, {helpId: ARTICLES[tab]})
    }

    return {tab, previousTab}
  },
)
