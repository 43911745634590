import {WidgetSettingsEventsKeys} from '../../interfaces'
import {getLoadedEventCount, hasMore, isLoading} from '../selectors/events'
import {createAsyncAction} from '../services/redux-toolkit'
import {updateComponentDraft} from './component-config'
import {editorSettingsMyEventsShowSetup} from './config'

interface SetActiveEventParams {
  event: wix.events.Event
  singleEventWidget: boolean
  recurringFilter: number
}

export const setActiveEvent = createAsyncAction<void, SetActiveEventParams>(
  'SET_ACTIVE_EVENT',
  async ({event, singleEventWidget, recurringFilter}, {getState, extra: {bi}}) =>
    editorSettingsMyEventsShowSetup({
      state: getState(),
      bi,
      singleEventWidget,
      recurringFilter,
      eventId: singleEventWidget ? event.id : null,
    }),
)

interface UpdateComponentDraftAndSetActiveEventParams {
  event: wix.events.Event
  singleEventWidget: boolean
  recurringFilter: number
}

export const updateComponentDraftAndSetActiveEvent = createAsyncAction<
  void,
  UpdateComponentDraftAndSetActiveEventParams
>('UPDATE_COMPONENT_DRAFT_AND_SET_ACTIVE_EVENT', async ({event, singleEventWidget, recurringFilter}, {dispatch}) => {
  await dispatch(updateSelectedEvents([event.id]))
  dispatch(setActiveEvent({event, singleEventWidget, recurringFilter}))
})

export const loadActiveEvent = createAsyncAction<wix.events.Event>(
  'LOAD_ACTIVE_EVENT',
  async (_, {getState, extra: {serverApi}}) => {
    const state = getState()
    const eventId = state.componentConfig.byEventId?.eventId?.[0]

    if (eventId) {
      const {event} = await serverApi.loadActiveEvent(eventId)
      return event
    }
  },
)

export const loadEvents = createAsyncAction(
  'LOAD_EVENTS',
  async (_, {getState, extra: {serverApi}}) => serverApi.loadEvents(getLoadedEventCount(getState())),
  {
    condition: (_, {getState}) => {
      const state = getState()
      const MINIMUM_LOADED_EVENTS = 5

      return (!isLoading(state) && hasMore(state)) || getLoadedEventCount(state) < MINIMUM_LOADED_EVENTS
    },
  },
)

export const showEvent = createAsyncAction<void, string>('SHOW_EVENT', async (eventId, {dispatch, getState}) => {
  const state = getState()
  const {componentConfig} = state
  const eventIds = [...componentConfig.byEventId.eventId]

  eventIds.push(eventId)

  await dispatch(updateSelectedEvents(eventIds))
})

export const hideEvent = createAsyncAction<void, string>(
  'HIDE_EVENT',
  async (eventId, {dispatch, getState, extra: {experiments, settings}}) => {
    const state = getState()
    const {componentConfig} = state
    let eventIds = [...componentConfig.byEventId.eventId]

    if (experiments.enabled('specs.events.ui.EventsEditorOOI')) {
      settings.publishEvent(WidgetSettingsEventsKeys.HideEvent, eventId)
    }

    eventIds = eventIds.filter(evId => evId !== eventId)

    await dispatch(updateSelectedEvents(eventIds))
  },
)

export const updateSelectedEvents = createAsyncAction<void, string[]>(
  'UPDATE_SELECTED_EVENTS',
  async (eventIds, {dispatch, getState}) => {
    const state = getState()
    const {componentConfig} = state

    dispatch(
      updateComponentDraft({
        byStatus: undefined,
        byEventId: {...componentConfig.byEventId, eventId: eventIds},
      }),
    )
  },
)
