import {useSettings as useYoshiSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import type {ISettingsParam} from '@wix/yoshi-flow-editor/tpa-settings'

export const useSettings = componentSettings => {
  const settings = useYoshiSettings()

  const get = (param: ISettingsParam) => {
    const value = settings.get(param)
    return value ?? (componentSettings.texts[param.key] || componentSettings[param.key])
  }

  return {
    get,
    set: settings.set,
  }
}
